import { isUndefined } from 'lodash';

import { formatUTCDate, formatUTCDateTime } from '../helpers/TimeHelper';
import { TripDTO, TripModel, TripContactsEmail, TripTraveler } from '../models/Trip/TripModel';

const mapTravelers = (data: TripTraveler[]): string =>
  data
    .flatMap<(string | undefined)[]>((t) => [[t.name?.firstName, t.name?.lastName]])
    .map<string>((t) => t.filter((i) => !isUndefined(i)).join(' '))
    .join(', ');

const mapEmails = (data: TripContactsEmail[]): string => {
  const mobileEmail = data.find((email) => email.type.startsWith('Mo') && !isUndefined(email.emailAddress));

  if (mobileEmail) {
    const toEmail = data.find(
      (email) => (email.type === 'To' || email.type === 'Booker') && !isUndefined(email.emailAddress),
    );

    return toEmail?.emailAddress ? toEmail.emailAddress : '';
  }

  const primaryEmail = data.find(
    (email) =>
      (email.type === 'To' || email.type === 'Booker') && email.isPrimary && !isUndefined(email.emailAddress),
  );

  return primaryEmail?.emailAddress ? primaryEmail.emailAddress : '';
};

const isUndefinedValue = (value?: string | number): string => (value && String(value)) || '';

export const mapTripModel = (data: TripDTO): TripModel => ({
  globalCustomerNumber: isUndefinedValue(data.identification?.globalCustomerNumber),
  accountId: isUndefinedValue(data.identification?.accountId),
  customerName: isUndefinedValue(data.identification?.customerName),
  id: isUndefinedValue(data.identification?.id),
  sourceId: isUndefinedValue(data.identification?.sourceId),
  recordLocator: isUndefinedValue(data.identification?.recordLocator),
  email: mapEmails(data.contacts?.emailAddresses ?? []),
  travelers: mapTravelers(data.travelers ?? []),
  tripStartDate: isUndefinedValue(data.tripDetails && formatUTCDate(data.tripDetails.tripStartDateTime)),
  tripEndDate: isUndefinedValue(data.tripDetails && formatUTCDate(data.tripDetails.tripEndDateTime)),
  gds: isUndefinedValue(data.identification?.gds),
  creationOfficeId: isUndefinedValue(data.identification?.creationOfficeId),
  bookingDateTime: isUndefinedValue(
    data.tripDetails?.bookingDateTime && formatUTCDateTime(data.tripDetails.bookingDateTime),
  ),
  sourceModifiedDateTime: isUndefinedValue(
    data.tripDetails?.sourceModifiedDateTime && formatUTCDateTime(data.tripDetails.sourceModifiedDateTime),
  ),
  lastModifiedDateTime: isUndefinedValue(
    data.tripDetails?.lastModifiedDateTime && formatUTCDateTime(data.tripDetails.lastModifiedDateTime),
  ),
  tripDTO: { ...data },
});

export const mapTripsModel = (data: TripDTO[]): TripModel[] => data.map((item) => mapTripModel(item));
