import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApiError } from '../../models/Api/ApiError';
import { tripPrefix } from '../actions/actionTypes';
import { TripResponseDTO } from '../../models/Trip/TripModel';
import { DataModel } from '../../models/Store/DataModel{T}';
import { fetchTripAction } from '../actions/tripActions';

const initialState: DataModel<TripResponseDTO | null> = {
  data: null,
  isLoading: false,
};

const tripSlice = createSlice({
  name: tripPrefix,
  initialState,
  reducers: {
    reset: () => initialState,
    set: (state, action: PayloadAction<TripResponseDTO>) => {
      state.data = { ...action.payload.trip };
    },
    clearError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTripAction.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchTripAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchTripAction.rejected, (state, action) => {
        state.data = null;
        state.isLoading = false;
        state.error = action.payload as ApiError;
      });
  },
});

export default tripSlice.reducer;
