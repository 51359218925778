import React, { useEffect, useState } from 'react';
import { Button, Grid, Icon, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import { i18n } from '../../../services/i18n';
import NamedInput from '../../NamedInput/NamedInput';
import { useValidationUtils } from '../../../hooks/Form/useValidationUtils';
import InfoTitle from '../../InfoTitle/InfoTitle';
import { useTrips } from '../../../store/hooks/useTrips/useTrips';
import {
  accountIdRules,
  globalCustomerNumberRules,
  handleValidateSearchTripsForm,
  isOtherFieldsDefault,
  isRequiredFieldFilled,
  sourceIdRules,
  travelerEmailAddressRules,
  travelerFirstNameRules,
  travelerLastNameRules,
} from '../../../helpers/SearchTripsFormHelpers';
import {
  SearchTripsFormViewModel,
  defaultSearchTripsFormValue,
} from '../../../models/SearchTrips/SearchTripsFormViewModel';

import './styles.scss';

interface Props {
  onClear: () => void;
}

const SearchTripsForm: React.FC<Props> = ({ onClear }) => {
  const [{ isLoading }, { getSearchTrips, handleResetTrips }] = useTrips();
  const [emptyFormMessage, setEmptyFormMessage] = useState<string>('');
  const [state, setState] = useState<SearchTripsFormViewModel>(defaultSearchTripsFormValue);
  const [isAllFieldsCleared, setIsAllFieldsCleared] = useState(false);
  const useFormValue = useForm<SearchTripsFormViewModel>({ defaultValues: defaultSearchTripsFormValue });
  const { register, formState, clearErrors, setError, watch } = useFormValue;
  const [{ isFieldsChanged }, { handleFormSubmit, handleStateChange, handlePropsChange }] =
    useValidationUtils<SearchTripsFormViewModel>({
      state,
      useFormValue,
      setState,
      showOrHideSaveModal: () => getSearchTrips(state),
      updateOnChange: true,
    });
  const sourceId = watch('sourceId');
  const travelerEmailAddress = watch('travelerEmailAddress');
  const travelerLastName = watch('travelerLastName');
  const travelerFirstName = watch('travelerFirstName');

  useEffect(() => {
    register('sourceId', sourceIdRules());
    register('travelerEmailAddress', travelerEmailAddressRules());
    register('travelerLastName', travelerLastNameRules());
    register('travelerFirstName', travelerFirstNameRules());
    register('globalCustomerNumber', globalCustomerNumberRules());
    register('accountId', accountIdRules());
  }, [register]);

  useEffect(() => {
    if (
      !!emptyFormMessage &&
      isRequiredFieldFilled({
        ...defaultSearchTripsFormValue,
        sourceId,
        travelerEmailAddress,
        travelerLastName,
        travelerFirstName,
      })
    )
      setEmptyFormMessage('');
  }, [
    sourceId,
    travelerEmailAddress,
    travelerLastName,
    travelerFirstName,
    emptyFormMessage,
    setEmptyFormMessage,
  ]);

  const handleChange = (value: string, fieldName: keyof SearchTripsFormViewModel) => {
    void handleStateChange(value, fieldName);
    if (value !== defaultSearchTripsFormValue[fieldName]) {
      setIsAllFieldsCleared(false);
      return;
    }

    const isOtherFieldsCleared = isOtherFieldsDefault(state, defaultSearchTripsFormValue, fieldName);
    setIsAllFieldsCleared(isOtherFieldsCleared);
  };

  const onSubmit = () => {
    const { isValid, message } = handleValidateSearchTripsForm(state, setError, clearErrors);

    setEmptyFormMessage(message);
    isValid && void handleFormSubmit();
  };

  const onClearSearch = () => {
    onClear();
    handlePropsChange(defaultSearchTripsFormValue);
    handleResetTrips();
  };

  return (
    <Grid className="search-trips-form-container" data-testid="search-trips-form">
      <Grid.Row columns={8}>
        <Grid.Column>
          <NamedInput
            autoFocus
            title={
              <InfoTitle
                content={i18n('TripSearchForm_TripIdentifier')}
                popupContent={i18n('TripSearchForm_TripIdentifier_PopupMessage')}
              />
            }
            value={state.sourceId}
            onChange={(v) => void handleChange(v, 'sourceId')}
            error={formState.errors.sourceId}
            data-testid="search-trips-form-trip-identifier"
          />
        </Grid.Column>
        <Grid.Column>
          <NamedInput
            title={i18n('TripSearchForm_Email')}
            value={state.travelerEmailAddress}
            onChange={(v) => void handleChange(v, 'travelerEmailAddress')}
            error={formState.errors.travelerEmailAddress}
            data-testid="search-trips-form-email"
          />
        </Grid.Column>
        <Grid.Column>
          <NamedInput
            title={i18n('TripSearchForm_TravelerLastName')}
            value={state.travelerLastName}
            onChange={(v) => void handleChange(v, 'travelerLastName')}
            error={formState.errors.travelerLastName}
            data-testid="search-trips-form-last-name"
          />
        </Grid.Column>
        <Grid.Column>
          <NamedInput
            title={i18n('TripSearchForm_TravelerFirstName')}
            value={state.travelerFirstName}
            onChange={(v) => void handleChange(v, 'travelerFirstName')}
            error={formState.errors.travelerFirstName}
            data-testid="search-trips-form-first-name"
          />
        </Grid.Column>
        <Grid.Column>
          <NamedInput
            title={i18n('TripSearchForm_GCN')}
            value={state.globalCustomerNumber}
            onChange={(v) => void handleChange(v, 'globalCustomerNumber')}
            error={formState.errors.globalCustomerNumber}
            data-testid="search-trips-form-gcn"
          />
        </Grid.Column>
        <Grid.Column>
          <NamedInput
            title={i18n('TripSearchForm_SMID')}
            value={state.accountId}
            onChange={(v) => void handleChange(v, 'accountId')}
            error={formState.errors.accountId}
            data-testid="search-trips-form-smid"
          />
        </Grid.Column>
        <Grid.Column textAlign="left">
          <Button
            primary
            onClick={onSubmit}
            disabled={isLoading}
            data-testid="search-trips-form-submit-control"
          >
            {i18n('SearchButtonTitle')}
          </Button>
          {isFieldsChanged && !isAllFieldsCleared && (
            <Button
              basic
              onClick={onClearSearch}
              color="blue"
              className="icon-square-button"
              data-testid="clear-results-button"
            >
              <Icon link name="close" color="blue" />
            </Button>
          )}
        </Grid.Column>
      </Grid.Row>
      {!!emptyFormMessage && (
        <Grid.Row className="empty-form-message" data-testid="search-trips-form-validation-message">
          <Grid.Column width={16}>
            <Message color="red" as="span">
              {emptyFormMessage}
            </Message>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default SearchTripsForm;
