import { Role } from './enums/UserRole';
import { EventType } from './enums/EventTypes';
import { maxAccountsPerTime } from './features/ConsumerTab/AccountsTab/AccountsFormConstants';

export const applicationLabels = {
  en: {
    Authentication_SinginHeader: 'Sign in to BCD Admin UI tool',
    Authentication_Email: 'Email',
    Authentication_EmailLogin: 'Email (login)',
    Authentication_Password: 'Password',
    Authentication_NewPassword: 'New Password',
    Authentication_Login: 'Login',
    Authentication_Change: 'Change',
    Authentication_Code: 'Code',
    Authentication_Loading: 'Loading',
    Authentication_ForgotPassword: 'Forgot your password?',
    Authentication_PasswordReset: 'Password reset',
    Authentication_BCDsingin: 'BCD sign in',
    Authentication_EmailSignIn: 'Email sign in',
    Authentication_ChangePassword: 'Change Password',
    Authentication_ChangeInfo:
      'You need to update your password because this is the first time you are signing in',
    Authentication_ChangePasswordSuccessfully: 'Password updated successfully',
    Authentication_BackToLogin: 'Go back to Login',
    Authentication_ForgotPasswordInfo:
      'Please enter your e-mail address below and we will send a verification code to you.',
    Authentication_VerificationCodeBtn: ' Email me verification code',
    Authentication_GoBack: 'Never mind, go back',
    Authentication_or: 'or',
    Authentication_ResetPasswordInfo: 'Please check your email for a confirmation code',
    Authentication_UserDoesNotExist:
      'User does not exist or can not be managed by Admin UI. Please contact your Administrator',
    Authentication_FooterAllRights: `© ${new Date().getFullYear()} BCD Travel, All rights reserved.`,
    Authentication_FooterTrademarks: 'TripSource® and BCD Travel® are registered trademarks of BCD Group',
    Privacy_policy: 'Privacy policy',
    Terms_and_conditions: 'Terms and conditions',
    Logo_ProjectName: 'Travel Data Platform',
    Logo_Slogan: 'Travel smart. Achieve more.',
    ErrorPageMessage: 'Error appeared',
    LogoutPageMessage: 'Logging you out...',
    Header_Logout: 'Logout',
    ApiAccess_Logout: 'Logout',
    ApiAccess_Trips: 'Trips',
    ApiAccess_Expenses: 'Expenses',
    ApiAccess_WaiversStandAlone: 'Waivers stand alone',
    ApiAccess_WaiversMatching: 'Waivers matching',
    ApiAccess_Xx1: 'XX1',
    ApiAccess_Receipts: 'Receipts',
    ApiAccess_Tickets: 'Tickets',
    ApiAccess_FlightAlerts: 'Flight Status',
    ApiAccess_Enh: 'ENH',
    UsersPage_Title: 'Users',
    UsersPage_CreateUserButton: 'Add user',
    UsersPage_UserCreatedMessage: 'User has been created successfully',
    UsersPage_UserEditedMessage: 'User data has been updated successfully',
    UsersPage_SearchPlaceholder: 'Search by User ID (Email), First name or Last name',
    UserRole_Admin: 'admin',
    EnabledStatusName: 'Enabled',
    DisabledStatusName: 'Disabled',
    Events_Link: 'Events',
    SearchButtonTitle: 'Search',
    TripSearchForm_TripIdentifier_PopupMessage: 'Trip Id, Source Id, Record Locator or Confirmation number',
    TripSearchForm_TripIdentifier: 'Trip Identifier',
    TripSearchForm_GCN: 'GCN',
    TripSearchForm_Email: 'Email',
    TripSearchForm_Email_IncorrectMessage: 'Enter a valid email',
    TripSearchForm_SMID: 'SMID',
    TripSearchForm_SMID_ToLongMessage: `Account id can't exceed 15 digits. Please review the values.`,
    TripSearchForm_SMID_ToShortMessage: `Account id can't be less than 6 digits. Please review the values.`,
    TripSearchForm_TravelerFirstName: 'Traveler First Name',
    TripSearchForm_Specify_TravelerFirstName: 'Please specify Traveler First Name',
    TripSearchForm_TravelerLastName: 'Traveler Last Name',
    TripSearchForm_Specify_TravelerLastName: 'Please specify Traveler Last Name',
    SearchTrips_Link: 'Search',
    SearchTrips_EmptyForm_Message:
      'Please specify one of the search parameters: Trip Identifier, Email, or a combination Traveler Last Name and Traveler First Name',
    SearchTrips_Trips_Title: 'Trips',
    SearchTrips_Itinerary_Title: 'Itinerary',
    SearchTrips_Raw_Title: 'Raw',
    SearchTrips_Modal_Title: 'Trip Details',
    SearchTrips_Modal_NotFound_Title: 'Not found',
    SearchTrips_Modal_NotFound_Trip_Message: 'Trips format data was not found for this trip.',
    SearchTrips_Modal_NotFound_Itinerary_Message: 'Itinerary format data was not found for this trip.',
    SearchTrips_Modal_NotFound_Raw_Message: 'Raw format data was not found for this trip.',
    SearchTrips_CodeContent_DataCopiedMessage: 'Data copied successfully!',
    TripsTable_GCN: 'GCN',
    TripsTable_SMID: 'SMID',
    TripsTable_CustomerName: 'Account Name',
    TripsTable_GDS: 'GDS',
    TripsTable_CreationOffice: 'Creation Office',
    TripsTable_TripId: 'Trip Id',
    TripsTable_SourceId: 'Source Id',
    TripsTable_RecordLocator: 'Record Locator',
    TripsTable_Email: 'Email',
    TripsTable_Traveler: 'Travelers',
    TripsTable_StartEndDate: 'Trip Dates',
    TripsTable_BookingDate: 'Booking Date',
    TripsTable_SourceModifiedDate: 'Source Modified Date',
    TripsTable_LastModifiedDate: 'Last Modified Date',
    TripsTable_Send_Control: 'Send',
    TripsTable_Routing: 'Routing',
    TripsTable_Direction_inbound: 'inbound',
    TripsTable_Direction_outbound: 'outbound',
    [`EventType_${EventType.IndiaBarcode}`]: '2D bar codes',
    [`EventType_${EventType.Receipt}`]: 'Receipts Messaging',
    SendEvents_Modal_Headline: 'Confirm email sending?',
    SendEvents_Modal_Content: 'You are about to send the email. Please select the event type before sending',
    SendEvents_Modal_EventTypeDropdown_Title: 'Event type',
    SendEvents_Modal_EventTypeDropdown_Placeholder: 'Select event type',
    SendEvents_Successfully: 'Event sent successfully',
    ConsumerForm_Create_Title: 'Create consumer',
    ConsumerForm_ConsumerKeyTitle: 'Name',
    ConsumerForm_ConsumerStartDateTitle: 'Start date',
    ConsumerForm_ConsumerEndDateTitle: 'End date',
    ConsumerForm_ConsumerStatusTitle: 'Status',
    ConsumerForm_ConsumerTypesTitle: 'Consumer types',
    ConsumerForm_ConsumerTypesPlaceholder: 'Set consumer type(s)',
    ConsumerForm_ContactsTitle: 'Contacts',
    ConsumerForm_ContactsEmailTitle: 'Email',
    ConsumerForm_ContactsTypeTitle: 'Type',
    ConsumerForm_ContactsTypePlaceholder: 'Select type',
    ConsumerForm_ContactsPrimaryTitle: 'Primary',
    ConsumerForm_ApiAccessTitle: 'API Access',
    ConsumerForm_AllowedApiAccess_Hint: 'Your user role only allows access to the Trips API',
    ConsumerForm_StartDateLaterMessage: 'Start date must be earlier than end date',
    ConsumerForm_EndDateEarlierMessage: 'End date must be later than start date',
    ConsumerForm_IsPrimaryMissingMessage: 'At least one Email with Primary status must be set',
    ConsumerForm_InvalidEmailMessage: 'Enter a valid email',
    ConsumerForm_InvalidKeyMessage: 'Please do not use incorrect characters',
    ConsumerForm_ConsumerExistsMessage: 'Consumer with this name already exists',
    ConsumerForm_ModalSaveCreate_Title: 'Confirm Consumer creation?',
    ConsumerForm_ModalSaveEdit_Title: 'Confirm Consumer editing?',
    ConsumerTripAccess_ApiResponseLabel: 'API Response',
    ConsumerTripAccess_AirSegmentLabel: 'Air',
    ConsumerTripAccess_HotelSegmentLabel: 'Hotel',
    ConsumerTripAccess_CarSegmentLabel: 'Car',
    ConsumerTripAccess_RailSegmentLabel: 'Rail',
    ConsumerTripAccess_BusSegmentLabel: 'Bus',
    ConsumerTripAccess_CruiseFerrySegmentLabel: 'CruiseFerry',
    ConsumerTripAccess_TourSegmentLabel: 'Tour',
    ConsumerTripAccess_FullViewButtonLabel: 'Full view',
    UserForm_ConsumerEmailDeleteButtonTitle: 'Delete',
    ConsumersPage_Title: 'Consumers',
    ConsumersPage_CreateConsumerButton: 'Create consumer',
    ConsumersPage_SearchPlaceholder: 'Search by Name, Consumer Id or OAuth ID',
    ConsumersPage_ExportCsvConsumerButton: 'Export CSV',
    ConsumersPage_ConsumerCreatedMessage:
      'Consumer has been created successfully.\nTo review the details please follow',
    ConsumersPage_ConsumerCreatedLink: 'the link.',
    ConsumerTable_Header_ConsumerName: 'Consumer name',
    ConsumerTable_Header_ConsumerId: 'Consumer ID',
    ConsumerTable_Header_ConsumerOAuthId: 'OAuth ID',
    ConsumerTable_Header_Status: 'Status',
    ConsumerTable_Header_PiiAccess: 'PII access',
    ConsumerTable_Header_EnhancedRemarks: 'Enhanced remarks',
    ConsumerTable_Header_ApiAccess: 'API access',
    ConsumerTable_No_Data: 'There are no consumers yet',
    Consumers_Link: 'Consumers',
    Faq_Link: 'FAQ',
    ConsumerInfoPage_ConsumerEditedMessage: 'Consumer has been updated successfully',
    ConsumerInfoGeneral_Link: 'General',
    ConsumerInfoAccounts_Link: 'Accounts',
    ConsumerInfoTrip_Link: 'Trip access',
    ConsumerStaticInfo_ConsumerIdTitle: 'Consumer ID',
    ConsumerStaticInfo_OauthIdTitle: 'OAuth ID',
    ConsumerStaticInfo_OauthSecretTitle: 'OAuth secret',
    ConsumerStaticInfo_CreatedAtTitle: 'Created at',
    ConsumerStaticInfo_CreatedByTitle: 'Created by',
    ConsumerStaticInfo_LastUpdatedAtTitle: 'Last updated at',
    ConsumerStaticInfo_LastUpdatedByTitle: 'Last updated by',
    ConsumerMainInfo_ConsumerNameTitle: 'Name',
    ConsumerMainInfo_ContactsTitle: 'Contacts',
    ConsumerMainInfo_EmailTitle: 'Email',
    ConsumerMainInfo_ApiAccessTitle: 'API access',
    ConsumerMainInfo_PrimaryTitle: 'Primary',
    ConsumerMainInfo_EnabledTitle: 'Enabled',
    ConsumerMainInfo_DisabledTitle: 'Disabled',
    ConsumerMainInfo_NoEmailsTitle: 'No emails have been provided.',
    ConsumerMainInfo_NoApiAccessTitle: 'No API access has been provided.',
    ConsumerMainInfo_EditButtonTitle: 'Edit',
    ConsumerMainInfo_StartDateTitle: 'Start date',
    ConsumerMainInfo_EndDateTitle: 'End date',
    ConsumerMainInfo_StatusTitle: 'Status',
    ConsumerMainInfo_ConsumerTypesTitle: 'Consumer types',
    ConsumerMainInfo_ConsumerTypesNotSetMessage: 'Not set',
    ConsumerAccounts_DisableButton: 'Disable',
    ConsumerAccounts_EnableButton: 'Enable',
    ConsumerAccounts_BulkEnableDisable_Hint:
      'To enable or disable accounts, please select at least one using the checkboxes.',
    ConsumerAccounts_AccountsLabel: 'Accounts logic',
    ConsumerAccounts_SearchPlaceholder: 'Search by name or SMID',
    ConsumerAccounts_AddButton: 'Add accounts',
    ConsumerAccounts_ChangeAccountsLogicButton: 'Change accounts logic',
    ConsumerAccounts_Inclusive: 'Inclusive',
    ConsumerAccounts_Exclusive: 'Exclusive',
    ConsumerAccounts_Inclusive_Hint:
      'Inclusive logic applied. Consumer has access only to the accounts in the table.',
    ConsumerAccounts_Exclusive_Hint:
      'Exclusive logic applied. Consumer has access to all accounts except accounts in the table.',
    ConsumerAccounts_InclusiveNoCustomerNumbers_Hint:
      'Access to this account is forbidden. Add at least 1 customer id or change the logic to Exclusive.',
    ConsumerAccounts_ExclusiveNoCustomerNumbers_Hint:
      'Access to this account is permitted. Add at least 1 customer id or change the logic to Exclusive.',
    ConsumerAccounts_Title_Line1_Exclusive:
      'Are you sure you want to apply Exclusive logic on accounts/SMIDs level?',
    ConsumerAccounts_Title_Line1_Inclusive:
      'Are you sure you want to apply Inclusive logic on accounts/SMIDs level?',
    ConsumerAccounts_Title_Line2_Part1_Regular: 'This action will',
    ConsumerAccounts_Title_Line2_Part2_Bold: ' delete ALL ',
    ConsumerAccounts_Title_Line2_Part3_Regular: 'accounts/SMIDs data!',
    ConsumerAccounts_ToExclusive_Content:
      'After confirmation, consumer will get access to all existing accounts except accounts in the table.',
    ConsumerAccounts_ToExclusive_YesButton: 'Yes, apply Exclusive logic',
    ConsumerAccounts_ToExclusive_NoButton: 'No, leave Inclusive logic',
    ConsumerAccounts_ToInclusive_Content:
      'After confirmation, consumer will get access only to the accounts in the table.',
    ConsumerAccounts_AcknowledgementMessage: 'I acknowledge that all existing accounts/SMIDs data will be deleted.',
    ConsumerAccounts_Status_Title: 'Confirm Account(s) changes?',
    ConsumerAccounts_Enable_Content:
      'The {{accountCount}} account(s) will be enabled. Please verify account(s) start and end dates after the confirmation.',
    ConsumerAccounts_Disable_Content: 'The {{accountCount}} account(s) will be disabled.',
    ConsumerAccounts_ToInclusive_YesButton: 'Yes, apply Inclusive logic',
    ConsumerAccounts_ToInclusive_NoButton: 'No, leave Exclusive logic',
    ConsumerAccounts_MaxNumberOfAccountsSelectedMessage: `The maximum number (${maxAccountsPerTime} accounts) of selected options per session has been reached.`,
    AccountLookupTable_CheckboxTooltip: 'Account {{accountId}} has been already added to this Consumer.',
    AccountLookupTable_SelectAllCheckboxTooltip: 'Maximum 500 accounts will be selected per once.',
    AccountLookupTable_Column_AccountName: 'Account name',
    AccountLookupTable_Column_AccountId: 'Account ID (SMID)',
    AccountLookupTable_Column_Gcn: 'GCN',
    AccountLookupTable_Column_CountryCode: 'Country code',
    AccountLookupTable_Column_CountryName: 'Country name',
    ConsumerTripAccessInfo_TripsScopeLabel: 'Trips scope',
    TripAccessPresetName: 'Schema preset',
    TripAccessAdvancedName: 'Advanced settings',
    TripAccessAdvancedNotification:
      'Please contact your Super Admin for advanced settings consumer configuration updates.',
    ConsumerTripAccessInfo_EditButtonTitle: 'Edit',
    TripAccessTab_GeneralAccess_Title: 'General access',
    TripAccessTab_PiiAccess_Title: 'PII access',
    TripAccessTab_RawData_Title: 'Raw data',
    TripAccessTab_DataSource_Title: 'Data source & Data provider',
    DataSource_NotSet_Text: 'Data provider is not specified',
    DataSource_NotSet_Text_Details: 'Consumer has access to all Data providers for the selected Data source.',
    DataSource_More_Control: 'more',
    DataSource_Selected: 'Selected',
    DataSource_Providers: 'providers',
    TripAccessForm_ModalSave_Title: 'Confirm Trip Access changes?',
    UserTable_Header_UserIdEmail: 'User ID (Email)',
    UserTable_Header_FirstName: 'First name',
    UserTable_Header_LastName: 'Last name',
    UserTable_Header_IsActive: 'Is active',
    UserTable_Header_Role: 'Role',
    UserTable_Header_ConsumerTypes: 'Consumer types',
    UserTable_Header_ConsumerTypesTooltip: 'User has access to the set consumer type(s) only',
    UserTable_No_Data: 'There are no users yet',
    UserTable_Dropdown_Edit: 'Edit',
    UserTable_Dropdown_Deactivate: 'Deactivate',
    AccountTable_Header_AccountName: 'Account name',
    AccountTable_Header_AccountId: 'Account ID (SMID)',
    AccountTable_Header_Status: 'Status',
    AccountTable_Header_CustomerLogic: 'Customers logic',
    AccountTable_Header_CustomerId: 'Customer ID',
    AccountTable_No_Data: 'There are no accounts yet',
    AccountTable_AccountRow_AddCustomerIdButton_Tooltip: 'Add Customer ID',
    AccountsForm_Create_Title: 'Add accounts',
    AccountsForm_Edit_Title: 'Edit account',
    AccountsForm_AccountIdTitle: 'AccountID (SMID)',
    AccountsForm_AccountsIdsTitle: 'AccountIDs (SMIDs)',
    AccountsForm_StartDateTitle: 'Start date',
    AccountsForm_EndDateTitle: 'End date',
    AccountsForm_CustomerNumberLogicTitle: 'Customers logic',
    AccountsForm_InclusiveAccountInclusiveCustomerNumbersUserMessage:
      'Inclusive logic applied. Consumer has access only to trips with the specified account and customer ids.',
    AccountsForm_InclusiveAccountExclusiveCustomerNumbersUserMessage:
      'Exclusive logic applied. Consumer has access to all trips with the specified account except for trips with customer ids listed in the table.',
    AccountsForm_ExclusiveAccountInclusiveCustomerNumbersUserMessage:
      'Inclusive logic applied. Consumer has access to all trips except for trips with the specified account and customer ids.',
    AccountsForm_ExclusiveAccountExclusiveCustomerNumbersUserMessage:
      'Exclusive logic applied. Consumer has access to all trips except for trips with the specified account if customer ids values are not matched with listed in the table.',
    AccountsForm_IncorrectFormatMessage:
      'Please enter a valid account id number including at least one digit. Allowed characters are digits (0-9), commas (,), dashes (-), and spaces',
    AccountsForm_AccountExistsMessage:
      'Account {{accountId}} has been already added to this Consumer. Please validate the entered values.',
    AccountsForm_MultipleAccountsExistMessage:
      'Several highlighted accounts have been already added to this Consumer. Please validate the entered values.',
    AccountsForm_TooManyAccounts_Message: `The values can't be added due to the limits. Max ${maxAccountsPerTime} accounts per time.`,
    AccountsForm_DuplicateAccounts_Message:
      'Duplicated account numbers within the input field are found. Please validate the entered values.',
    AccountsForm_TooLongAccountId_Message: "Account id can't exceed 15 digits. Please review the values.",
    AccountsForm_ModalSaveEdit_Title: 'Confirm Account changes?',
    AccountsForm_ModalSaveCreate_Title: 'Confirm Accounts adding?',
    AccountsForm_ToInclusiveCustomerNumbers_NoButton: 'No, leave Exclusive logic',
    AccountsForm_ToInclusiveCustomerNumbers_YesButton: 'Yes, apply Inclusive logic',
    AccountsForm_ToExclusiveCustomerNumbers_NoButton: 'No, leave Inclusive logic',
    AccountsForm_ToExclusiveCustomerNumbers_YesButton: 'Yes, apply Exclusive logic',
    AccountsForm_ToInclusiveAccountInclusiveCustomerNumbers_Content:
      'After confirmation, consumer will get access only to trips with the specified account and customer ids.',
    AccountsForm_ToInclusiveAccountExclusiveCustomerNumbers_Content:
      'After confirmation, consumer will get access to all trips with the specified account except for trips with customer ids listed in the table.',
    AccountsForm_ToExclusiveAccountInclusiveCustomerNumbers_Content:
      'After confirmation, consumer will get access only to the accounts in the table.',
    AccountsForm_ToExclusiveAccountExclusiveCustomerNumbers_Content:
      'After confirmation, consumer will get access to all trips except for trips with the specified account if customer ids values are not matched with those listed in the table.',
    AccountsForm_Title_Line1_Exclusive: 'Are you sure you want to apply Exclusive logic on customers level?',
    AccountsForm_Title_Line1_Inclusive: 'Are you sure you want to apply Inclusive logic on customers level?',
    AccountsForm_Title_Line2_Part1_Regular: 'This action will',
    AccountsForm_Title_Line2_Part2_Bold: ' delete ALL ',
    AccountsForm_Title_Line2_Part3_Regular: 'customer ids data within the account!',
    CustomerNumbersForm_Create_Title: 'Add customer id',
    CustomerNumbersForm_Edit_Title: 'Edit customer id',
    CustomerNumbersForm_CustomerNumberIdTitle: 'Customer id',
    CustomerNumbersForm_CustomerNumberExistsMessage: 'Access to the customer id already exists/forbidden',
    CustomerNumbersForm_ModalSaveEdit_Title: 'Confirm Customer id changes?',
    CustomerNumbersForm_ModalSaveCreate_Title: 'Confirm Customer id adding?',
    InclusiveLogicName: 'Inclusive',
    ExclusiveLogicName: 'Exclusive',
    Users_Link: 'Users',
    UserForm_Create_Title: 'Create user',
    UserForm_Edit_Title: 'Edit user',
    Form_RequiredMessage: 'This field is required',
    Form_InvalidCharactersMessage: 'Please do not use incorrect characters',
    UserForm_InvalidEmailMessage: 'Enter a valid User ID (Email)',
    UserForm_InvalidPhoneMessage: 'Enter the phone number in the format +XX XXXX',
    [`Users_RoleName_${Role.SuperAdmin}`]: 'Super Admin',
    [`Users_RoleName_${Role.Admin}`]: 'Admin',
    [`Users_RoleName_${Role.Consumers}`]: 'Consumers',
    [`Users_RoleName_${Role.ConsumersRead}`]: 'Consumers read',
    [`Users_RoleName_${Role.Events}`]: 'Events',
    [`Users_RoleName_${Role.Search}`]: 'Search',
    InactiveStatusName: 'Not Active',
    ActiveStatusName: 'Active',
    UserForm_FirstNameTitle: 'First name',
    UserForm_LastNameTitle: 'Last name',
    UserForm_InvalidNameMessage: 'Please do not use incorrect characters',
    UserForm_EmailTitle: 'User ID (Email)',
    UserForm_NumberTitle: 'Contact number (optional)',
    UserForm_RolesTitle: 'Roles',
    UserForm_NoRolesFound: 'No roles found.',
    UserForm_Placeholder: 'Select role(s)',
    UserForm_ConsumerTypesTitle: 'Consumer types',
    UserForm_ConsumerTypesPlaceholder: 'Set consumer type(s)',
    UserForm_StatusTitle: 'Is active',
    UserForm_CancelButtonTitle: 'Cancel',
    UserForm_SaveButtonTitle: 'Save',
    UserForm_ModalSaveCreate_Title: 'Confirm User creation?',
    UserForm_ModalSaveEdit_Title: 'Confirm User editing?',
    UserForm_EmailExistsMessage: 'User with this User ID (Email) already exists',
    UserForm_FederatedTitle: 'Is federated',
    Faq_Title: 'Data Publication & Subscription Services: Admin - FAQ - Internal',
    Faq_GeneralQuestions: 'General Questions',
    Faq_GeneralQuestions_WhatIsAdminUi: 'What is Admin UI?',
    Faq_GeneralQuestions_WhatIsAdminUi_Content:
      'Admin UI is an API management tool that enables the creation of API consumers, enforcing their usage policies, and controlling access.',
    Faq_GettingStarted: 'Getting Started',
    Faq_GettingStarted_CreateUser: 'How to create a user on Admin UI?',
    Faq_GettingStarted_CreateUser_Description: 'To add a new user, follow the steps in this section:',
    Faq_GettingStarted_CreateUser_First: 'Select the Users tab.',
    Faq_GettingStarted_CreateUser_Second: 'Press Add User button.',
    Faq_GettingStarted_CreateUser_Third: 'Enter the appropriate information for the user.',
    Faq_GettingStarted_CreateUser_Fourth: 'Click Save.',
    Faq_GettingStarted_CreateUser_Message:
      'For creating a user without existing access to Admin UI, please contact the administrator.',
    Faq_GettingStarted_SetUp_Role: 'How to set up a specific role for the user?',
    Faq_GettingStarted_SetUp_Role_Description: 'To set up the role, follow the steps in the section:',
    Faq_GettingStarted_SetUp_Role_First: 'Select the Users tab.',
    Faq_GettingStarted_SetUp_Role_Second: 'Press Add User button.',
    Faq_GettingStarted_SetUp_Role_Third: 'Enter the appropriate information for the user.',
    Faq_GettingStarted_SetUp_Role_Fourth: 'Click Save.',
    Faq_GettingStarted_SetUp_Role_Message: 'Only Super Admin can set up Super Admin and Admin roles.',
    Faq_GettingStarted_DeactivateUser: 'How to deactivate User on Admin UI?',
    Faq_GettingStarted_DeactivateUser_Description: 'To deactivate the user, follow the steps in the section:',
    Faq_GettingStarted_DeactivateUser_First: 'Select the desired user from the list of available users.',
    Faq_GettingStarted_DeactivateUser_Second: 'By clicking on the user’s row open the Edit Users form.',
    Faq_GettingStarted_DeactivateUser_Third: 'Pick the Not Active button in the Is active status.',
    Faq_GettingStarted_DeactivateUser_Fourth: 'Click Save.',
    Faq_GettingStarted_LogIn: 'How to log in on Admin UI?',
    Faq_GettingStarted_LogIn_Description: 'To log in on Admin UI, follow the steps in this section:',
    Faq_GettingStarted_LogIn_First: 'Follow the URL.',
    Faq_GettingStarted_LogIn_Second: 'Enter appropriate User information.',
    Faq_GettingStarted_LogIn_Third: 'Click Log In.',
    Faq_GettingStarted_LogIn_Message:
      'In case of unsuccessful login, make sure you copied credentials without delimiters and spaces, otherwise contact the Administrator.',
    Faq_GettingStarted_BcdSso: 'Can I use BCD SSO to log in?',
    Faq_GettingStarted_BcdSso_Description:
      'Yes, logging with BCD SSO is available by clicking BCD Sign In button.',
    Faq_ConsumerConfiguration: 'Admin UI Consumer Configuration',
    Faq_ConsumerConfiguration_HowCreateConsumer: 'How to create a Consumer with Admin UI?',
    Faq_ConsumerConfiguration_HowCreateConsumer_Description:
      'To create a new consumer, follow the steps in this section:',
    Faq_ConsumerConfiguration_HowCreateConsumer_First: 'Select the Consumers tab.',
    Faq_ConsumerConfiguration_HowCreateConsumer_Second: 'Press Create Consumer button.',
    Faq_ConsumerConfiguration_HowCreateConsumer_Third: 'Enter the appropriate information for the consumer.',
    Faq_ConsumerConfiguration_HowCreateConsumer_Fourth: 'Click Save.',
    Faq_ConsumerConfiguration_HowCreateConsumer_Message:
      'Once the consumer is created, the user should add appropriate accounts. Follow the instruction on How to add Accounts to a Consumer? Otherwise, consumers will not get any data.',
    Faq_ConsumerConfiguration_HowDisableConsumer: 'How to disable a Consumer on Admin UI?',
    Faq_ConsumerConfiguration_HowDisableConsumer_Description:
      'To disable consumer, follow the steps in the section:',
    Faq_ConsumerConfiguration_HowDisableConsumer_First:
      'Select the desired consumer from the list of available consumers.',
    Faq_ConsumerConfiguration_HowDisableConsumer_Second:
      "By clicking on the consumer's row, open the General consumer configuration page.",
    Faq_ConsumerConfiguration_HowDisableConsumer_Third: 'Click on the Edit button.',
    Faq_ConsumerConfiguration_HowDisableConsumer_Fourth: 'Pick the Disabled button in Status.',
    Faq_ConsumerConfiguration_HowDisableConsumer_Fifth: 'Click Save.',
    Faq_ConsumerConfiguration_HowAddAccounts: 'How to add Accounts to a Consumer?',
    Faq_ConsumerConfiguration_HowAddAccounts_Description:
      'To add accounts to a consumer, follow the steps in the section:',
    Faq_ConsumerConfiguration_HowAddAccounts_First:
      'Select the desired consumer from the list of available consumers.',
    Faq_ConsumerConfiguration_HowAddAccounts_Second:
      "By clicking on the consumer's row, open the General Consumer configuration page.",
    Faq_ConsumerConfiguration_HowAddAccounts_Third: 'Select the Accounts tab.',
    Faq_ConsumerConfiguration_HowAddAccounts_Fourth: 'Click on Add Account button.',
    Faq_ConsumerConfiguration_HowAddAccounts_Fifth: 'Enter the appropriate information for the account.',
    Faq_ConsumerConfiguration_HowAddAccounts_Sixth: 'Select customer id logic if necessary.',
    Faq_ConsumerConfiguration_HowAddAccounts_Seventh: 'Click Save.',
    Faq_ConsumerConfiguration_HowAddAccounts_Message:
      'By default, Inclusive accounts logic will be applied. The consumer will have access only to the accounts in the table. To start with data providing add at least one account. To get more information, follow ',
    Faq_ConsumerConfiguration_HowAddAccounts_MessageBold: 'What is Accounts logic?',
    Faq_ConsumerConfiguration_HowDisableAccess: 'How to disable access to the Account?',
    Faq_ConsumerConfiguration_HowDisableAccess_Description:
      'To add accounts to a consumer, follow the steps in the section:',
    Faq_ConsumerConfiguration_HowDisableAccess_First:
      'Select the desired account from the list of available accounts',
    Faq_ConsumerConfiguration_HowDisableAccess_Second:
      "By clicking on the account's row, open the Edit Account form.",
    Faq_ConsumerConfiguration_HowDisableAccess_Third: 'Pick the Disabled button in Status.',
    Faq_ConsumerConfiguration_HowDisableAccess_Fourth: 'Click Save.',
    Faq_ConsumerConfiguration_WhatIsAccountsLogic: 'What is Accounts logic?',
    Faq_ConsumerConfiguration_WhatIsAccountsLogic_Description:
      'For convenience, users may select one of the available account logic options. The logic is common for all accounts within a particular consumer. See the table with the description below.',
    Faq_ConsumerConfiguration_WhatIsAccountsLogic_Message:
      'Users can change accounts logic for the consumer by clicking on the appropriate button in the Accounts logic.',
    Faq_ConsumerConfiguration_WhatIsAccountsLogic_ColumnStatus: 'Status',
    Faq_ConsumerConfiguration_WhatIsAccountsLogic_ColumnDescription: 'Description',
    Faq_ConsumerConfiguration_WhatIsAccountsLogic_ColumnStatus_Inclusive: 'Inclusive',
    Faq_ConsumerConfiguration_WhatIsAccountsLogic_ColumnStatus_Exclusive: 'Exclusive',
    Faq_ConsumerConfiguration_WhatIsAccountsLogic_ColumnDescription_Inclusive:
      'Inclusive logic applied. Consumer has access only to the accounts in the table.',
    Faq_ConsumerConfiguration_WhatIsAccountsLogic_ColumnDescription_Exclusive:
      'Exclusive logic applied. Consumer has access to all accounts except accounts in the table.',
    Faq_ConsumerConfiguration_WhatIsAccountsLogic_MessageBold: 'All existing accounts data will be lost!',
    Faq_ConsumerConfiguration_HowAddCustomerId: 'How to Add Customer ID to a Consumer?',
    Faq_ConsumerConfiguration_HowAddCustomerId_Description:
      'User can allow/deny access not only on the accounts level but also allow/deny access to the specific customer id within the accounts access.',
    Faq_ConsumerConfiguration_HowAddCustomerId_UnderDescription:
      'To add access to the customer id, follow the steps in the section:',
    Faq_ConsumerConfiguration_HowAddCustomerId_First:
      'Select a desired account from the list of available accounts.',
    Faq_ConsumerConfiguration_HowAddCustomerId_Second:
      'For each account Add Customer ID button is located in the correspondent account raw.',
    Faq_ConsumerConfiguration_HowAddCustomerId_Third:
      'By clicking on Add Customer ID button, open the Add Customer ID form.',
    Faq_ConsumerConfiguration_HowAddCustomerId_Fourth: 'Enter appropriate information for the Customer ID.',
    Faq_ConsumerConfiguration_HowAddCustomerId_Fifth: 'Click Save.',
    Faq_ConsumerConfiguration_HowAddCustomerId_Message:
      'For the account, by default, Exclusive Customer ID logic applied. Consumer has access to all trips except for trips with customer ids listed in the table. User can change the customer id logic for the account. To get more information, follow ',
    Faq_ConsumerConfiguration_HowAddCustomerId_MessageBold: 'What is Customer ID logic?',
    Faq_ConsumerConfiguration_WhatIsCustomerId: 'What is Customer IDs logic?',
    Faq_ConsumerConfiguration_WhatIsCustomerId_Description:
      'For the convenience, user may select one of the available customer id logic options. The logic is common for all customer ids within the account. Consumer access is different based on both accounts and customer ids logic. See the table with the description below.',
    Faq_ConsumerConfiguration_WhatIsCustomerId_Message:
      'User can change customer Id logic for the account by clicking on the appropriate button in the Edit Account form. ',
    Faq_ConsumerConfiguration_WhatIsCustomerId_MessageBold: 'All existing customer ids data will be lost!',
    Faq_ConsumerConfiguration_WhatIsCustomerId_Column_AccountStatus: 'Account status',
    Faq_ConsumerConfiguration_WhatIsCustomerId_Column_CustomerIdStatus: 'Customer ID status',
    Faq_ConsumerConfiguration_WhatIsCustomerId_Column_Description: 'Description',
    Faq_ConsumerConfiguration_WhatIsCustomerId_Row1_Decription:
      'Inclusive logic applied. Consumer has access only to trips with the specified account and customer IDs.',
    Faq_ConsumerConfiguration_WhatIsCustomerId_Row2_Decription:
      'Exclusive logic applied. Consumer has access to all trips with the specified account except for trips with customer IDs listed in the table.',
    Faq_ConsumerConfiguration_WhatIsCustomerId_Row3_Decription:
      'Inclusive logic applied. Consumer has access to all trips except for trips with the specified account and customer IDs.',
    Faq_ConsumerConfiguration_WhatIsCustomerId_Row4_Decription:
      'Exclusive logic applied. Consumer has access to all trips except for trips with the specified account if customer IDs values are not matched with listed in the table.',
    Faq_ConsumerConfiguration_HowEnableAccess: 'How enable access to the specific API?',
    Faq_ConsumerConfiguration_HowEnableAccess_Description:
      'Users can allow/deny access to the available APIs in the General tab.',
    Faq_ConsumerConfiguration_HowConfigureAccess: 'How to configure access to the Trips API?',
    Faq_ConsumerConfiguration_HowConfigureAccess_Description:
      'To configure Trips API access for the consumer, follow the steps in this section.',
    Faq_ConsumerConfiguration_HowConfigureAccess_First:
      'Select the desired consumer from the list of available consumers.',
    Faq_ConsumerConfiguration_HowConfigureAccess_Second:
      "By clicking on the consumer's row, open the General tab.",
    Faq_ConsumerConfiguration_HowConfigureAccess_Third: 'Select the Trips Access tab.',
    Faq_ConsumerConfiguration_WhatAreSchemaPresets: 'What are Schema presets?',
    Faq_ConsumerConfiguration_WhatAreSchemaPresets_Description:
      'For convenience, we defined the most popular presets for the API access configuration. Users can select one of the predefined options and see the configuration in JSON format.',
    Faq_ConsumerConfiguration_WhatAreSchemaPresets_Message:
      'The settings will not be saved until the Save button is clicked.',
    Faq_ConsumerConfiguration_HowUseAdvancedSettings: 'How to use Advanced settings?',
    Faq_ConsumerConfiguration_HowUseAdvancedSettings_Description:
      'Admin UI provides the possibility to allow/deny access for each field in the API schema. By clicking on Advanced settings, API schema will be presented in JSON format. Users can manage custom configurations for a specific consumer.',
    Faq_ConsumerConfiguration_HowUseAdvancedSettings_Message:
      'The settings will not be saved until the Save button is clicked.',
    Faq_ConsumerConfiguration_NotAvailableOnDisabledPII:
      'What data is not available to the Consumer when PII access is disabled?',
    Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description:
      'In such case consumer will not have access to:',
    Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description_Trip:
      'Trip level: customer name, traveler`s personal and contact information (name, country, profile status, contact details, seat preferences, identifiers, loyalty membership, etc), remarks, and enhanced remarks. ',
    Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description_Air:
      'For air, bus, rail, and cruise ferry segments: notes, segment remarks; ',
    Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description_HotelSegment:
      'For hotel segment: reservation name, credit card details, overview, loyalty membership details, notes, and segment remarks;',
    Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description_TourSegment:
      'For tour segment: corporate segment number, guarantee indicator, notes, segment remarks;',
    Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description_CarSegment:
      'For car segment: reservation name, loyalty membership details, credit card details, notes, segment remarks;',
    Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description_TicketsNode:
      'For tickets node: customer name, traveler`s personal information, credit card details.',
    Faq_Search: 'Search',
    Faq_Search_WhatIsSearch: 'What is the "Search" functionality?',
    Faq_Search_WhatIsSearch_Description:
      'Search functionality allows searching and viewing trips in itinerary, trips, and raw formats.',
    Faq_Search_WhatParametersCanUtilize: 'What parameters can the User utilize to search for trips?',
    Faq_Search_WhatParametersCanUtilize_First: 'Trip Identifier, which includes:',
    Faq_Search_WhatParametersCanUtilize_First_A: 'Trip Id',
    Faq_Search_WhatParametersCanUtilize_First_B: 'Source Id',
    Faq_Search_WhatParametersCanUtilize_First_C: 'Confirmation number',
    Faq_Search_WhatParametersCanUtilize_First_D: 'Record Locator',
    Faq_Search_WhatParametersCanUtilize_Second: "Traveler's primary email address",
    Faq_Search_WhatParametersCanUtilize_Third: 'Traveler First Name and Traveler Last Name Combination',
    Faq_Events: 'Events',
    Faq_Events_WhatIsEvents: 'What is the "Events" functionality?',
    Faq_Events_WhatIsEvents_Description:
      'The Events functionality enables users to search for trips and send the desired event, such as a 2D barcode or a trip receipt.',
    Faq_Events_WhatParametersCanUtilize: 'What parameters can the User utilize to search for trips?',
    Faq_Events_WhatParametersCanUtilize_First: '* Trip Identifier, which includes:',
    Faq_Events_WhatParametersCanUtilize_First_A: 'Trip Id',
    Faq_Events_WhatParametersCanUtilize_First_B: 'Source Id',
    Faq_Events_WhatParametersCanUtilize_First_C: 'Confirmation number',
    Faq_Events_WhatParametersCanUtilize_First_D: 'Record Locator',
    Faq_Events_WhatParametersCanUtilize_Second: 'Trip Identifier + a combination of any parameter:',
    Faq_Events_WhatParametersCanUtilize_Second_A: 'GCN',
    Faq_Events_WhatParametersCanUtilize_Second_B: 'SMID',
    Faq_Events_WhatParametersCanUtilize_Second_C: 'Email',
    Faq_Events_WhatParametersCanUtilize_Second_D: 'Traveler First Name',
    Faq_Events_WhatParametersCanUtilize_Second_E: 'Traveler Last Name',
    TimeHelper_Utc: 'UTC',
    Time_Title: 'Time',
    Messages_ErrorMessage: 'Something went wrong. Please try again later',
    Messages_BadRequestMessage: 'Entered information was malformed. Please check messages below.',
    NotFederatedStatusName: 'Not federated',
    FederatedStatusName: 'Federated',
    Table_No_Result: 'No results found. Please restart the search',
    Table_Header_StartDate: 'Start date',
    Table_Header_EndDate: 'End date',
    Table_Header_DateTimeTooltip: 'YYYY-MM-DD hh:mm UTC',
    Modals_Save_Content: 'Changes will be applied immediately',
    Modals_Save_IsUserConsumerTypesDeleted_Content:
      'You are about to delete all consumer types available to you. After confirmation this consumer will become inaccessible to you.',
    Modals_Save_ConsumerWithCustomPIIAccess_Content: 'Default PII access configuration will be changed.',
    Modals_Save_SaveButton: 'Yes, confirm',
    Modals_SaveAdding_CancelButton: 'No, back to adding',
    Modals_SaveCreation_CancelButton: 'No, back to creation',
    Modals_SaveEditing_CancelButton: 'No, back to editing',
    Modals_Leave_Create_Title: 'Leave creation without saving?',
    Modals_Leave_Edit_Title: 'Leave editing without saving?',
    Modals_Leave_Add_Title: 'Leave adding without saving?',
    Modals_Leave_Content: 'Entered information will not be saved',
    Modals_Leave_Create_ContinueButton: 'No, back to creation',
    Modals_Leave_Edit_ContinueButton: 'No, back to editing',
    Modals_Leave_Add_ContinueButton: 'No, back to adding',
    Modals_Leave_LeaveButton: 'Yes, leave without saving',
    Modals_Base_ConfirmButton: 'Confirm',
    Modals_Base_CancelButton: 'Cancel',
    Footer_RightsLabel: `© ${new Date().getFullYear()} BCD Travel, All rights reserved. TripSource® and BCD Travel® are registered trademarks of BCD Group.`,
    Footer_PrivacyPolicyLinkLabel: 'Privacy policy',
    Footer_TermsAndConditionLinkLabel: 'Terms and conditions',
    NoAccessPage_Title: 'Access is denied',
    NoAccessPage_Content: 'Please contact your Administrator',
    NotFoundPage_Title: 'Page is not found',
    NotFoundPage_Content: 'Please double check URL or page existing',
    NotFoundPage_Link: 'API Admin Page',
    Message_Note: 'Note',
    NoResultsFound: 'No results found.',
  },
};
