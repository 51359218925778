import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { History } from 'history';
import { Container, Header, Menu, Modal, Tab } from 'semantic-ui-react';

import {
  searchTripItineraryResponsePath,
  searchTripRawResponsePath,
  searchTripResponsePath,
  searchTripsPath,
} from '../../../paths';
import TripModalContent from '../TripResponse/TripModalContent';
import ItineraryModalContent from '../TripResponse/ItineraryModalContent';
import RawModalContent from '../TripResponse/RawModalContent';
import { useTrip } from '../../../store/hooks/useTrip';
import { useRawTrip } from '../../../store/hooks/useRawTrip';
import { useItineraryTrip } from '../../../store/hooks/useItineraryTrip';
import { i18n } from '../../../services/i18n';

import './styles.scss';

const SearchTripsModal: React.FC = () => {
  const [, { handleResetTrip }] = useTrip();
  const [, { handleResetRawTrip }] = useRawTrip();
  const [, { handleResetItineraryTrip }] = useItineraryTrip();
  const history: History = useHistory();
  const params: { id: string } = useParams();
  const tripResponsePath = searchTripResponsePath(params.id);
  const itineraryResponsePath = searchTripItineraryResponsePath(params.id);
  const rawResponsePath = searchTripRawResponsePath(params.id);

  const tabMapping: Record<string, number> = {
    [tripResponsePath]: 0,
    [itineraryResponsePath]: 1,
    [rawResponsePath]: 2,
  };
  const getCurrentTab = (path: string): number => {
    return tabMapping[path] || 0;
  };
  const defaultActiveTab = getCurrentTab(history.location.pathname);

  useEffect(() => {
    return () => {
      handleResetTrip();
      handleResetItineraryTrip();
      handleResetRawTrip();
    };
  }, [handleResetTrip, handleResetItineraryTrip, handleResetRawTrip]);

  return (
    <Modal open closeIcon="close" onClose={() => history.push(searchTripsPath)} className="trip-modal">
      <Modal.Content>
        <Header as="h2" className="trip-modal-headline">
          {i18n('SearchTrips_Modal_Title')}
        </Header>
        <Tab
          className="trip-modal-tab"
          menu={{ secondary: true, pointing: true }}
          panes={[
            {
              menuItem: (
                <Menu.Item key="trip-response" as={Link} to={tripResponsePath}>
                  {i18n('SearchTrips_Trips_Title')}
                </Menu.Item>
              ),
              render: () => (
                <Container className="trip-modal-tab-content">
                  <TripModalContent />
                </Container>
              ),
            },
            {
              menuItem: (
                <Menu.Item key="itinerary" as={Link} to={itineraryResponsePath}>
                  {i18n('SearchTrips_Itinerary_Title')}
                </Menu.Item>
              ),
              render: () => (
                <Container className="trip-modal-tab-content">
                  <ItineraryModalContent />
                </Container>
              ),
            },
            {
              menuItem: (
                <Menu.Item key="raw" as={Link} to={rawResponsePath}>
                  {i18n('SearchTrips_Raw_Title')}
                </Menu.Item>
              ),
              render: () => (
                <Container className="trip-modal-tab-content">
                  <RawModalContent />
                </Container>
              ),
            },
          ]}
          activeIndex={defaultActiveTab}
        />
      </Modal.Content>
    </Modal>
  );
};

export default SearchTripsModal;
