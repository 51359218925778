import { Action, createAsyncThunk } from '@reduxjs/toolkit';

import {
  fetchItineraryTripActionName,
  fetchRawTripActionName,
  fetchTripActionName,
  resetItineraryTripActionName,
  resetRawTripActionName,
  resetTripActionName,
  setTripActionName,
} from './actionTypes';
import {
  FetchRawTripActionModel,
  FetchTripActionModel,
  TripRequestDTO,
  TripResponseDTO,
} from '../../models/Trip/TripModel';
import { TripService } from '../../services/TripService';

const service = TripService;

export const fetchTripAction = createAsyncThunk<FetchTripActionModel, TripRequestDTO>(
  fetchTripActionName,
  async (model, thunkApi) => {
    try {
      const response = await service.getTrip(model);

      return {
        data: response,
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const fetchItineraryTripAction = createAsyncThunk<FetchTripActionModel, TripRequestDTO>(
  fetchItineraryTripActionName,
  async (model, thunkApi) => {
    try {
      const response = await service.getItineraryTrip(model);

      return {
        data: response,
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const fetchRawTripAction = createAsyncThunk<FetchRawTripActionModel, TripRequestDTO>(
  fetchRawTripActionName,
  async (model, thunkApi) => {
    try {
      const response = await service.getRawTrip(model);

      return {
        data: response,
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const setTripAction: (trip: TripResponseDTO) => Action = (trip) => ({
  type: setTripActionName,
  payload: { trip },
});

export const resetTripAction: () => Action = () => ({
  type: resetTripActionName,
});

export const resetItineraryTripAction: () => Action = () => ({
  type: resetItineraryTripActionName,
});

export const resetRawTripAction: () => Action = () => ({
  type: resetRawTripActionName,
});
