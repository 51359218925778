import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-less/semantic.less';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import App from './App';
import ErrorPage from './pages/ErrorPage';
import { amplifyConfiguration } from './services/configurationBuilder';

amplifyConfiguration
  .then((_) => renderComponentToRoot(<App />))
  .catch((reason) => {
    console.error(JSON.stringify(reason));
    renderComponentToRoot(<ErrorPage />);
  });

function renderComponentToRoot(component: React.JSX.Element) {
  const root = document.getElementById('root');
  ReactDOM.render(component, root);
}
