import { I18n } from 'aws-amplify';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from './features/Root';
import { applicationLabels } from './glossary';
import { BreakpointProvider } from './hooks/BreakPointProvider/breakpoint';
import { UserProvider } from './hooks/User/user';
import './styles/main.scss';
import { mediaQueries } from './variables';
import { Provider } from 'react-redux';
import store from './store/store';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <BreakpointProvider queries={mediaQueries}>
        <UserProvider>
          <Router>
            <Root />
          </Router>
        </UserProvider>
      </BreakpointProvider>
    </Provider>
  );
};

I18n.setLanguage('en');
I18n.putVocabularies(applicationLabels);

export default App;
