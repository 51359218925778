import { useCallback } from 'react';
import { TripRequestDTO, TripResponseDTO, UseTripValuesInterface } from '../../models/Trip/TripModel';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { fetchTripAction, resetTripAction, setTripAction } from '../actions/tripActions';

export interface UseTripCallbacksInterface {
  getTrip: (payload: TripRequestDTO) => void;
  setTrip: (payload: TripResponseDTO) => void;
  handleResetTrip: () => void;
}

export const useTrip: () => [UseTripValuesInterface, UseTripCallbacksInterface] = () => {
  const dispatch = useAppDispatch();
  const tripState = useAppSelector((s) => s.trip);

  const getTrip = useCallback(
    (payload: TripRequestDTO) => {
      void dispatch(fetchTripAction(payload));
    },
    [dispatch],
  );

  const setTrip = useCallback(
    (payload: TripResponseDTO) => {
      dispatch(setTripAction(payload));
    },
    [dispatch],
  );

  const handleResetTrip = useCallback(() => {
    dispatch(resetTripAction());
  }, [dispatch]);

  return [
    {
      trip: tripState.data,
      isLoading: tripState.isLoading,
      error: tripState.error,
      successMessage: tripState.successMessage,
    },
    {
      getTrip,
      setTrip,
      handleResetTrip,
    },
  ];
};
