import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Dimmer, Loader } from 'semantic-ui-react';

import { useItineraryTrip } from '../../../store/hooks/useItineraryTrip';
import CodeContent from '../CodeContent/CodeContent';
import NotFoundMessage from './NotFoundMessage';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { i18n } from '../../../services/i18n';

const ItineraryModalContent: React.FC = () => {
  const [{ trip, isLoading, error }, { getItineraryTrip }] = useItineraryTrip();
  const { id: paramsTripId }: { id: string } = useParams();
  const isNotFound = (error?.response?.status && error.response.status === 404) || false;
  const spacesToIndent = 4;

  useEffect(() => {
    trip ?? getItineraryTrip({ id: paramsTripId });
  }, [trip, getItineraryTrip, paramsTripId]);

  return (
    <Container placeholder className="trip-modal-content-container">
      {isLoading && (
        <Dimmer active inverted>
          <Loader size="medium" />
        </Dimmer>
      )}
      {trip && <CodeContent data={JSON.stringify(trip, undefined, spacesToIndent)} />}
      {!isLoading && isNotFound && (
        <NotFoundMessage
          dataTestid="itinerary-modal-not-found-message"
          message={i18n('SearchTrips_Modal_NotFound_Itinerary_Message')}
        />
      )}
      {!isLoading && error && !isNotFound && <ErrorMessage dataTestid="itinerary-modal-error-message" />}
    </Container>
  );
};

export default ItineraryModalContent;
