import { useCallback } from 'react';
import { TripRequestDTO, UseTripValuesInterface } from '../../models/Trip/TripModel';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { fetchItineraryTripAction, resetItineraryTripAction } from '../actions/tripActions';

export interface UseTripsCallbacksInterface {
  getItineraryTrip: (payload: TripRequestDTO) => void;
  handleResetItineraryTrip: () => void;
}

export const useItineraryTrip: () => [UseTripValuesInterface, UseTripsCallbacksInterface] = () => {
  const dispatch = useAppDispatch();
  const tripState = useAppSelector((s) => s.itineraryTrip);

  const getItineraryTrip = useCallback(
    (payload: TripRequestDTO) => {
      void dispatch(fetchItineraryTripAction(payload));
    },
    [dispatch],
  );

  const handleResetItineraryTrip = useCallback(() => {
    dispatch(resetItineraryTripAction());
  }, [dispatch]);

  return [
    {
      trip: tripState.data,
      isLoading: tripState.isLoading,
      error: tripState.error,
      successMessage: tripState.successMessage,
    },
    {
      getItineraryTrip,
      handleResetItineraryTrip,
    },
  ];
};
