import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Dimmer, Loader } from 'semantic-ui-react';

import { useTrip } from '../../../store/hooks/useTrip';
import CodeContent from '../CodeContent/CodeContent';
import NotFoundMessage from './NotFoundMessage';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { i18n } from '../../../services/i18n';

import './styles.scss';

const TripModalContent: React.FC = () => {
  const [{ trip, isLoading, error }, { getTrip }] = useTrip();
  const { id: paramsTripId }: { id: string } = useParams();
  const isNotFound = (error?.response?.status && error.response.status === 404) || false;
  const spacesToIndent = 4;

  useEffect(() => {
    trip ?? getTrip({ id: paramsTripId });
  }, [trip, getTrip, paramsTripId]);

  return (
    <Container className="trip-modal-content-container">
      {isLoading && (
        <Dimmer active inverted>
          <Loader size="medium" />
        </Dimmer>
      )}
      {trip && <CodeContent data={JSON.stringify(trip, undefined, spacesToIndent)} />}
      {!isLoading && isNotFound && (
        <NotFoundMessage
          dataTestid="trip-modal-not-found-message"
          message={i18n('SearchTrips_Modal_NotFound_Trip_Message')}
        />
      )}
      {!isLoading && error && !isNotFound && <ErrorMessage dataTestid="itinerary-modal-trip-message" />}
    </Container>
  );
};

export default TripModalContent;
