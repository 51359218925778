import { RegisterOptions, UseFormClearErrors, UseFormSetError } from 'react-hook-form';

import {
  getTravelerRule,
  getSourceIdRule,
  getGlobalCustomerNumberRule,
  getAccountIdRule,
  getEmailRule,
  getAccountIdValidationRule,
  isOtherFieldsDefault,
} from './FormHelpers';
import { SearchTripsFormViewModel } from '../models/SearchTrips/SearchTripsFormViewModel';
import { i18n } from '../services/i18n';

export const sourceIdRules: () => RegisterOptions<SearchTripsFormViewModel, 'sourceId'> = getSourceIdRule;

export const globalCustomerNumberRules: () => RegisterOptions<
  SearchTripsFormViewModel,
  'globalCustomerNumber'
> = getGlobalCustomerNumberRule;

export const accountIdRules: () => RegisterOptions<SearchTripsFormViewModel, 'accountId'> = () => ({
  ...getAccountIdRule(),
  validate: getAccountIdValidationRule,
});

export const travelerEmailAddressRules: () => RegisterOptions<
  SearchTripsFormViewModel,
  'travelerEmailAddress'
> = getEmailRule;

export const travelerFirstNameRules: () => RegisterOptions<SearchTripsFormViewModel, 'travelerFirstName'> =
  getTravelerRule;

export const travelerLastNameRules: () => RegisterOptions<SearchTripsFormViewModel, 'travelerLastName'> =
  getTravelerRule;

export const isTravelerFilled = (firstName: string, lastName: string): boolean => !!firstName && !!lastName;

export const isTravelerFilledPartly = (firstName: string, lastName: string): boolean =>
  (!!firstName || !!lastName) && !isTravelerFilled(firstName, lastName);

export const isRequiredFieldFilled = ({
  sourceId,
  travelerEmailAddress,
  travelerFirstName,
  travelerLastName,
}: SearchTripsFormViewModel): boolean =>
  !!sourceId || !!travelerEmailAddress || isTravelerFilled(travelerFirstName, travelerLastName);

export const isRequiredFieldsFilledCorrect = (state: SearchTripsFormViewModel): boolean => {
  const { travelerFirstName, travelerLastName } = state;
  const requiredFieldsFilled = isRequiredFieldFilled(state);

  return (
    (requiredFieldsFilled && isTravelerFilled(travelerFirstName, travelerLastName)) ||
    (requiredFieldsFilled && !travelerFirstName && !travelerLastName)
  );
};

export const handleValidateSearchTripsForm: (
  state: SearchTripsFormViewModel,
  setError: UseFormSetError<SearchTripsFormViewModel>,
  clearErrors: UseFormClearErrors<SearchTripsFormViewModel>,
) => { isValid: boolean; message: string } = (state, setError, clearErrors) => {
  const { travelerFirstName, travelerLastName } = state;
  const travelerFilledPartly = isTravelerFilledPartly(travelerFirstName, travelerLastName);
  let message = '';

  if (isRequiredFieldsFilledCorrect(state)) return { isValid: true, message: '' };

  if (!isTravelerFilled(travelerFirstName, travelerLastName) && !travelerFilledPartly) {
    message = i18n('SearchTrips_EmptyForm_Message');
    clearErrors('travelerFirstName');
    clearErrors('travelerLastName');
  }

  if (travelerFilledPartly) {
    !!travelerFirstName &&
      setError('travelerLastName', {
        message: i18n('TripSearchForm_Specify_TravelerLastName'),
      });

    !!travelerLastName &&
      setError('travelerFirstName', {
        message: i18n('TripSearchForm_Specify_TravelerFirstName'),
      });
  }

  return { isValid: false, message };
};

export { isOtherFieldsDefault };
