import clsx from 'clsx';
import ErrorMessage from '../../features/ErrorMessage/ErrorMessage';
import React from 'react';
import SearchTripsModal from '../../features/SearchTrips/SearchTripsModal/SearchTripsModal';
import SearchTripsTable from '../../features/SearchTrips/SearchTripsTable/SearchTripsTable';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useBreakpoint } from '../../hooks/BreakPointProvider/breakpoint';
import { Route } from 'react-router-dom';
import { useTrips } from '../../store/hooks/useTrips/useTrips';
import './styles.scss';

import {
  searchTripItineraryResponsePath,
  searchTripRawResponsePath,
  searchTripResponsePath,
} from '../../paths';

const SearchTripsResult: React.FC = () => {
  const [{ isLoading: isTripsLoading, error: tripsError }] = useTrips();
  const breakpoints = useBreakpoint();
  const isNotFound = (tripsError?.response?.status && tripsError.response.status === 404) || false;

  return (
    <div className="search-trips-page-content-container">
      {isTripsLoading && (
        <Dimmer active inverted data-testid="search-trips-page-loader">
          <Loader size="massive" />
        </Dimmer>
      )}

      <div
        className={clsx('search-trips-content-container', { 'table-sticky-container': !breakpoints.xs })}
        data-testid="search-trips-content-container"
      >
        {!isTripsLoading && (!tripsError || isNotFound) && <SearchTripsTable />}
        {tripsError && !isNotFound && <ErrorMessage dataTestid="search-trips-page-error-message" />}
      </div>

      <Route
        path={[searchTripResponsePath(), searchTripItineraryResponsePath(), searchTripRawResponsePath()]}
        component={SearchTripsModal}
      />
    </div>
  );
};

export default SearchTripsResult;
