import { createSlice } from '@reduxjs/toolkit';

import { ApiError } from '../../models/Api/ApiError';
import { rawTripPrefix } from '../actions/actionTypes';
import { RawTripResponseDTO } from '../../models/Trip/TripModel';
import { DataModel } from '../../models/Store/DataModel{T}';
import { fetchRawTripAction } from '../actions/tripActions';

const initialState: DataModel<RawTripResponseDTO> = {
  data: null,
  isLoading: false,
};

const rawTripSlice = createSlice({
  name: rawTripPrefix,
  initialState,
  reducers: {
    reset: () => initialState,
    clearError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRawTripAction.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchRawTripAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchRawTripAction.rejected, (state, action) => {
        state.data = null;
        state.isLoading = false;
        state.error = action.payload as ApiError;
      });
  },
});

export default rawTripSlice.reducer;
