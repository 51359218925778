import { useCallback } from 'react';

import { ApiError } from '../../models/Api/ApiError';
import { RawTripResponseDTO, TripRequestDTO } from '../../models/Trip/TripModel';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { fetchRawTripAction, resetRawTripAction } from '../actions/tripActions';

export interface UseRawTripValuesInterface {
  trip: RawTripResponseDTO;
  isLoading: boolean;
  error?: ApiError;
  successMessage?: string;
}
export interface UseTripsCallbacksInterface {
  getRawTrip: (payload: TripRequestDTO) => void;
  handleResetRawTrip: () => void;
}

export const useRawTrip: () => [UseRawTripValuesInterface, UseTripsCallbacksInterface] = () => {
  const dispatch = useAppDispatch();
  const tripState = useAppSelector((s) => s.rawTrip);

  const getRawTrip = useCallback(
    (payload: TripRequestDTO) => {
      void dispatch(fetchRawTripAction(payload));
    },
    [dispatch],
  );

  const handleResetRawTrip = useCallback(() => {
    dispatch(resetRawTripAction());
  }, [dispatch]);

  return [
    {
      trip: tripState.data,
      isLoading: tripState.isLoading,
      error: tripState.error,
      successMessage: tripState.successMessage,
    },
    {
      getRawTrip,
      handleResetRawTrip,
    },
  ];
};
